<template>
  <div>
    <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners'
               :title='title' width='980px' append-to-body>
      <div class='panel-box'>
        <div>
          <div>
            <el-form inline v-model='searchCondition' size='mini'>
              <el-form-item>
                <el-date-picker
                  v-model='searchCondition.year'
                  type='year'
                  format='yyyy年'
                  value-format='yyyy'
                  placeholder='选择年度' @change='handleQuery' style='width: 140px'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select v-model='searchCondition.quarter' placeholder='选择季度' @change='handleQuery'
                           style='width: 175px'
                           clearable>
                  <el-option :label='quarter.label' :key='index' :value='quarter.value'
                             v-for='(quarter,index) in quarters'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model='searchCondition.dept_name' placeholder='部门搜索' clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model='searchCondition.manager_name' placeholder='总监搜索' clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click='handleQuery'>查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button icon='el-icon-plus' @click='handleAdd'>新增</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data='dataList' @sort-change='changeTableSort' max-height='500' stripe highlight-current-row>
            <el-table-column label='季度' prop='year' sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.year }}年第{{ row.quarter }}季度</span>
              </template>
            </el-table-column>
            <el-table-column label='MCN中心' prop='dept_name' sortable='custom' show-overflow-tooltip></el-table-column>
            <el-table-column label='MCN负责人' prop='manager_name' sortable='custom'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <div style='display:flex;flex-direction: row;gap: 10px'>
                  <span>{{ row.manager_name || '-' }}</span>
                  <EditManager :form-data.sync='row' :key='`EditManager${row.id}`'></EditManager>
                </div>

              </template>
            </el-table-column>
            <el-table-column label='负责部门' prop='manager_name' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.children_count || 0 }} 个</span>
              </template>
            </el-table-column>
            <el-table-column label='添加时间' prop='created_at' show-overflow-tooltip></el-table-column>
            <el-table-column label='操作' align='left' width='120'>
              <template slot-scope='{row}'>
                <el-tooltip class='item' effect='light' content='设置MCN下考核部门' placement='left'>
                  <el-button type='text' icon='el-icon-setting' @click='handleAddDept(row)'></el-button>
                </el-tooltip>
                &nbsp;
                <el-link type='danger' icon='el-icon-close' @click='handleRemove(row)'></el-link>
              </template>
            </el-table-column>
          </el-table>
          <m-pagination @pagiation='getList' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                        :total.sync='total'></m-pagination>
        </div>
        <div v-show='currentRow.id'>
          <!--          MCN表格底部-显示部门内已添加考核的红人-->
          <PaDeptEdit ref='refPaDept'></PaDeptEdit>
        </div>
      </div>
      <!--         新增： 考核部门设置：一般是中心部门-->
      <PaSettingAdd ref='refPaSettingAdd' @saved='handleQuery'></PaSettingAdd>

    </el-dialog>
    <!--    修改总监-->
    <SelectManager ref='refSelectManager' @handleSelect='handleSelectManager'></SelectManager>
  </div>
</template>

<script>
import CompanyCascader from '@/components/dept/CompanyCascader'
import UserSearch from '@/pages/user/components/UserSearch'
import PaDeptAdd from '@/pages/pa/components/PaDeptAdd'
import PaSettingAdd from '@/pages/pa/components/PaSettingAdd'
import PaDeptEdit from '@/pages/pa/components/PaDeptEdit'
import EditManager from '@/pages/pa/components/EditManager'

export default {
  name: 'PaDeptSetting',
  components: { EditManager, PaDeptEdit, PaSettingAdd, PaDeptAdd, UserSearch, CompanyCascader },
  data() {
    return {
      dialogVisible: false,
      title: '考核部门（总监绩效）设置',
      dataList: [],
      searchCondition: {
        year: null,
        quarter: null,
        dept_id: null,
        dept_name: null,
        manager_name: null,
        manager_id: null
      },
      total: 0,
      pageData: {
        page_size: 5,
        current_page: 1
      },
      orderBy: { year: 'desc', quarter: 'desc' },
      currentRow: {},
      quarters: [
        // { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ]

    }
  },
  methods: {
    show(query) {
      this.dialogVisible = true
      this.currentRow = {}
      this.searchCondition.year = query ? query.year : null
      this.searchCondition.quarter = query ? query.quarter : null
      this.getList()
    },
    defaultQuarter() {
      //当前季度
      this.formData.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.formData.quarter = this.$utils.getCurrentQuarter()
    },
    handleSearchCondition() {
      let cond = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          cond[field] = this.searchCondition[field]
      })
      //仅查询MCN中心
      cond['pid'] = 0
      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderBy = orderSort
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData, { orderBy: this.orderBy })
      let { list, pages } = await this.$api.getPaDeptSettingList(searchCondition)
      this.dataList = list || []
      this.pageData.page_size = pages.page_size || 10
      this.pageData.current_page = pages.current_page || 1
      this.total = pages.total || 0
    },
    handleRemove(row) {
      let posIndex = this.dataList.indexOf(row)

      let msg = `确认移除MCN中心部门：“${row.dept_name}”，移除后后续将不参与考核？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.dataList.splice(posIndex, 1)
        await this.$api.deletePaDeptSetting(row.id)
        await this.handleQuery()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })
    },


    async handleAdd() {
      this.$refs['refPaSettingAdd'].show()
      // this.$refs['refPaDeptAdd'].close()
      this.$refs['refPaDept'].close()
    },

    // eslint-disable-next-line no-unused-vars
    handleAddDept(row) {
      this.currentRow = { ...row }
      //MCN中心下的部门
      this.handleRefreshPaDept(row)

      this.$refs['refPaSettingAdd'].close()

    },
    handleRefreshPaDept() {
      this.$refs['refPaDept'].show(this.currentRow)
    },
    handleEditManager(row) {
      this.currentEditRow = row
      this.$notify.success('修改总监')
      this.$refs['refSelectManager'].show()
    },
    handleSelectManager(val) {
      if (val && val.user_id) {
        this.currentEditRow.manager_id = val.user_id
        this.currentEditRow.manager_name = val.nickname
        this.$api.editPaDeptSettingManager({
          id: this.currentEditRow.id,
          manager_id: val.user_id,
          manager_name: val.nickname
        })
      }

    }

  }
}
</script>

<style>

.panel-box {
  min-height: 600px;
  width: 100%;
  margin: auto;
}

.gradient-background {
  background: linear-gradient(to right, red, yellow);
}

.transparent-element {
  opacity: 0.5; /* 透明度值，0 表示完全透明，1 表示完全不透明 */
}

.float-layout {
  float: left; /* 可以是 'left' 或 'right' */
  clear: both; /* 清除浮动 */
}

</style>
