<template>
  <div class='add-panel shadow-effect' v-show='showAddBox'>
    <div style='display: flex;flex-direction: row;justify-content: space-between'>
      <!--      <h3>{{ title}} 考核设置</h3>-->
      <div>&nbsp;</div>
      <span class='el-icon-close' @click='showAddBox=false'></span>
    </div>
    <div>
      <div
        style='border-top: #f2f2f2 1px solid;margin-top: 10px;display: flex;flex-direction: row;gap: 5px;justify-content: space-between'>
        <h4 @click='handleQuery' style='cursor: pointer'>{{ searchCondition.year }}年第{{
            searchCondition.quarter
          }}季度《{{ searchCondition.dept_name }}》考核部门(负责人：{{ searchCondition.manager_name }})</h4>

        <el-link type='primary' @click='handleAdd' icon='el-icon-plus'>添加部门</el-link>
      </div>
      <div>
        <div>
          <el-table :data='dataList' stripe highlight-current-row max-height='600'>
            <el-table-column type='index' label='序号'></el-table-column>
            <el-table-column label='季度考核部门' min-width='120'>
              <template slot-scope='{row}'>
                <span>{{ row.year }}年第{{ row.quarter }}季度</span><br />
                <span>{{ row.dept_name }} </span>
                <span>{{ row.manager_name }} <el-link type='primary' icon='el-icon-edit-outline'
                                                      @click='handleEditManager(row)'></el-link></span>
              </template>
            </el-table-column>
            <el-table-column label='考核红人' min-width='300'>
              <template slot-scope='{row}'>
                <div v-if='row.kol_range'>
                  <el-tag
                    style='margin-right: 5px'
                    v-for='(tag,idx) in row.kol_range'
                    :key='`${tag.artist_id}_${idx}`'
                    effect='plain'
                    type='primary'>
                    {{ tag.nickname }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label='操作' align='left' width='120px'>
              <template slot-scope='{row}'>
                <el-link type='primary' icon='el-icon-edit' @click='showDeptKolSelection(row)'>修改红人</el-link>
                <br />
                <el-link type='warning' icon='el-icon-refresh' @click='handleInitItem(row)' :loading='loadingInit'
                         :disabled='loadingInit'>
                  <span class='el-icon-loading' v-if='loadingInit'></span>初始化
                </el-link>
                <br />
                <el-link type='danger' icon='el-icon-close' @click='handleRemove(row)'>移除</el-link>
              </template>
            </el-table-column>
          </el-table>
          <m-pagination @pagination='getPaDeptList' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                        :total.sync='total'></m-pagination>
        </div>
        <!--        部门红人设置-->
        <DeptKolSelect ref='refDeptKolSelect' @saved='handleQuery'></DeptKolSelect>
      </div>

      <!--         设置各个部门的考核红人和初始化（添加MCN中心下参与考核的部门）-->
      <PaDeptAdd ref='refPaDeptAdd'
                 @saved='handleQuery'></PaDeptAdd>
    </div>
    <!--    修改总监-->
    <SelectManager ref='refSelectManager' @handleSelect='handleSelectManager'></SelectManager>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import MemberSelect from '@/pages/pa/components/MemberSelect'
import DeptKolSelect from '@/pages/pa/components/DeptKolSelect'
import PaDeptAdd from '@/pages/pa/components/PaDeptAdd'
import SelectManager from '@/pages/pa/components/SelectManager'

export default {
  name: 'PaDeptEdit',
  components: { SelectManager, PaDeptAdd, DeptKolSelect, MemberSelect, ArtistSearch },
  props: {
    showDeptAdd: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    title() {
      return `${this.searchCondition.year}年第${this.searchCondition.quarter}季度《${this.searchCondition.dept_name}》`
    }
  },
  data() {
    return {
      showAddBox: false,
      searchCondition: {
        id: null,
        dept_id: null,
        dept_name: null,
        year: null,
        quarter: null,
        manager_name: null,
        manager_id: null
      },
      deptList: [
        // { dept_id: '1', dept_name: '项目一部' },
        // { dept_id: '2', dept_name: '项目二部' }
      ],
      currentRow: {},
      currentKol: {},
      KolDataList: [],
      formData: {
        dept_id: null,
        dept_name: '',
        year: '',
        quarter: '',
        pid: null,
        'kol_range': [],
        'manager_id': '',
        'manager_name': ''
      },
      keyWord: null,
      dataList: [],
      loadingStatus: false,
      loadingInit: false,
      loadingCommit: false,
      total: 0,
      pageData: {
        page_size: 5,
        current_page: 1
      },
      showKolSelection: false,
      selection: [],
      currentEditRow: {}
    }
  },
  methods: {
    show(row) {
      this.searchCondition = { ...row }
      this.showAddBox = true
      this.formData.pid = row.dept_id
      this.formData.year = row.year
      this.formData.quarter = row.quarter
      //获取当前指定中心部门的下级部门
      // if (this.showDeptAdd)
      //   this.getNextDeptList()
      //已添加的记录
      this.getPaDeptList()
    },
    close() {
      this.showAddBox = false
    },
    async getNextDeptList() {
      if (this.searchCondition.dept_id) {
        let { list } = await this.$api.getNextDeptList(this.searchCondition.dept_id)
        this.deptList = list || []
      }

    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getPaDeptList()
    },
    async getPaDeptList() {
      this.loadingStatus = true
      this.dataList = []
      let searchCondition = { pid: this.searchCondition.id }
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getPaDeptSettingList(searchCondition)
      this.dataList = list || []
      this.pageData.page_size = pages.page_size || 10
      this.pageData.current_page = pages.current_page || 1
      this.total = pages.total || 0
    },
    async handleInitItem(row) {
      this.loadingInit = true
      setTimeout(() => {
        this.loadingInit = false
      }, 2000)
      let { err } = await this.$api.initItemPaChiefRevenue(row.id)
      if (err) {
        if (err.length > 0) {
          let errMsgArr = err.map(item => {
            return item.err_msg
          })
          this.$message.warning(errMsgArr.join('、'))
        }

      } else {
        this.$notify.success('初始化完毕')
      }

    },
    // eslint-disable-next-line no-unused-vars
    handleRemove(row) {
      let msg = `确认移除部门：“${row.dept_name}”，移除后后续将不参与考核？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        let posIndex = this.dataList.indexOf(row)
        if (posIndex > -1) {
          this.dataList.splice(posIndex, 1)
        }
        if (row.id) {
          this.$api.deletePaDeptSetting(row.id)
        }
      }).catch(() => {
        this.$notify.info('取消操作')
      })
    },
    showDeptKolSelection(row) {
      // this.showKolSelection = true
      this.$refs['refDeptKolSelect'].show(row)
    },
    handleAdd() {
      // MCN部门添加
      this.$refs['refPaDeptAdd'].show(this.searchCondition)
    },
    handleEditManager(row) {
      this.currentEditRow = row
      this.$notify.success('修改总监')
      this.$refs['refSelectManager'].show()
    },
    handleSelectManager(val) {
      if (val && val.user_id) {
        this.currentEditRow.manager_id = val.user_id
        this.currentEditRow.manager_name = val.nickname
        // let posIndex = this.dataList.indexOf(this.currentEditRow)
        // this.dataList[posIndex]['manager_id'] = val.user_id
        // this.dataList[posIndex]['manager_name'] = val.nickname
        this.$api.editPaDeptSettingManager({
          id: this.currentEditRow.id,
          manager_id: val.user_id,
          manager_name: val.nickname
        })
      }

    }

  }
}
</script>

<style scoped>

.add-panel {
  padding: 10px;
  margin: 10px;
}

.add-box {
  /*border: #e97ca1 1px dashed;*/
  display: flex;
  flex-direction: row;
  gap: 5px;
}

</style>
