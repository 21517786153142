<template>
  <div>
    <el-link type='primary' icon='el-icon-edit-outline'
             @click='handleEditManager(formData)'></el-link>
    <!--    修改总监/负责人-->
    <select-manager ref='refSelectManager' @handleSelect='handleSelectManager'></select-manager>
  </div>
</template>

<script>
import SelectManager from '@/pages/pa/components/SelectManager'

export default {
  name: 'EditManager',
  components: { SelectManager },
  props: {
    formData: {
      type: Object
    }
  },
  data() {
    return {
      currentEditRow: {}
    }
  },
  methods: {
    handleEditManager(row) {
      this.currentEditRow = row
      this.$notify.success(`正在修改【${row.dept_name}】总监`)
      this.$refs['refSelectManager'].show()
    },
    handleSelectManager(val) {
      if (val && val.user_id) {
        this.currentEditRow.manager_id = val.user_id
        this.currentEditRow.manager_name = val.nickname
        this.$emit('saved', { manager_id: val.user_id, manager_name: val.nickname })
        // let posIndex = this.dataList.indexOf(this.currentEditRow)
        // this.dataList[posIndex]['manager_id'] = val.user_id
        // this.dataList[posIndex]['manager_name'] = val.nickname
        this.$api.editPaDeptSettingManager({
          id: this.formData.id,
          manager_id: val.user_id,
          manager_name: val.nickname
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
