<template>
  <el-dialog :visible.sync='showAddBox' v-bind='$attrs' v-on='$listeners'
             :title='title' width='800px' append-to-body>
    <div class='add-panel shadow-effect'>
      <!--      <div style='display: flex;flex-direction: row;justify-content: space-between'>-->
      <!--        &lt;!&ndash;      <h3>{{ title}} 考核设置</h3>&ndash;&gt;-->
      <!--        <div>&nbsp;</div>-->
      <!--        <span class='el-icon-close' @click='showAddBox=false'></span>-->
      <!--      </div>-->
      <div>
        <div>
          <div class='flex-sb'>
            <h3 style='cursor: pointer'>新增考核部门-{{ title }}</h3>
            <el-button size='mini' type='text' @click='showMore=!showMore'
                       :icon="showMore?'el-icon-caret-right':'el-icon-caret-left'">更多
            </el-button>
          </div>
          <div class='add-box'>
            <div style='width: 100%'>
              <el-table
                :key='`tbl_dept_${showMore?"c":"s"}`'
                :data='deptList'
                border
                stripe
                style='width: 100%'
                @selection-change='handleSelectionChange'
                @select='handleSelectionRowChange'
                @current-change='handleCurrentChange'
                highlight-current-row>
                <el-table-column label='序号' type='index' width='50'></el-table-column>
                <el-table-column type='selection' width='55'></el-table-column>
                <el-table-column label='部门' prop='dept_name' min-width='100px'>
                  <!--                <template slot-scope='{row}'>-->
                  <!--                  <el-radio :label='row' v-model='currentRow'>{{ row.dept_name || '' }}</el-radio>-->
                  <!--                </template>-->
                </el-table-column>
                <!--              <el-table-column label='总监' align='center' prop='manager_name' width='140'></el-table-column>-->
                <el-table-column label='总监' align='center' width='140'>
                  <template slot-scope='{row,$index}'>
                    <MemberSelect v-if='ids.indexOf(row.dept_id)>-1' :show-refresh-btn='false' :row-index='$index'
                                  :key='`refMemberSelect_${row.dept_id}`'
                                  :ref='`refMemberSelect_${row.dept_id}`' placeholder='组员' :dept-id='row.dept_id'
                                  @handleSelect='selectRowUser'></MemberSelect>
                    <span v-else>{{ row.manager_name || '' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label='操作' width='140' align='center' v-if='showMore'>
                  <template slot-scope='{row}'>
                    <div v-if='currentRow.dept_id&& currentRow.dept_id === row.dept_id'>
                      <el-link type='warning' icon='el-icon-refresh-left' @click='currentRow={}'>取消设置</el-link>
                    </div>
                    <el-link v-if='currentRow.dept_id !== row.dept_id' type='primary' icon='el-icon-setting'
                             @click='handleCurrentChange(row)'>
                      设置
                    </el-link>

                  </template>
                </el-table-column>
              </el-table>
              <div style='text-align: center;margin-top: 8px' v-if='!showMore'>
                <el-button round type='primary' :disabled='!multiple||loadingCommit' :loading='loadingCommit'
                           @click='handleBatchSave'>
                  设置为{{ title }}考核部门（{{ selection.length }}）
                </el-button>
              </div>

            </div>
            <div v-if='currentRow.dept_id' style='display: flex;flex-direction: column;gap: 5px'>
              <!--          选择总监-->
              <div class='theme-border-dashed'
                   style='display: flex;flex-direction: column;gap: 5px;padding: 10px;border-radius: 10px;'>
                <div>
                  <span> {{ currentRow.dept_name }}(ID:{{ currentRow.dept_id }})</span>
                </div>
                <div style='display: flex;flex-direction: row;gap: 4px;justify-content: left;align-items: center'>
                  <span style='color: red'>*</span>总监
                  <MemberSelect ref='refDeptMemberSelect' placeholder='组员' :dept-id='currentRow.dept_id'
                                @handleSelect='selectUser'></MemberSelect>

                </div>
                <div style='display: flex;flex-direction: row;gap: 4px;justify-content: left;align-items: center'>
                  <span title='若总监有调整，可通过搜索确认'>&nbsp;&nbsp;搜索</span>
                  <UserSearch ref='refUserSearch' :keyword.sync='keyWord'
                              @selected='selectUser'></UserSearch>
                </div>

              </div>

              <!--          选择考核红人-->
              <div style='width: 460px;border-left: #f2f2f2 1px solid;padding: 4px'>
                <div style='display: flex;flex-direction: row;align-items: center'>
                  <artist-search @handleSelect='handleSelectArtist'></artist-search>
                  <el-button icon='el-icon-plus' type='text' @click='appendToKolRange'>添加红人</el-button>
                  <el-tooltip class='item' effect='light' content='可后添加或部门总监列表批量追加' placement='right'>
                    <span class='el-icon-question'></span>
                  </el-tooltip>
                </div>
                <el-table :data='formData.kol_range' stripe>
                  <el-table-column label='序号' type='index' width='55'></el-table-column>
                  <el-table-column label='考核红人' align='left' prop='nickname' mini-width='120px'>

                  </el-table-column>
                  <el-table-column label='备注' align='left' mini-width='220px'>
                    <template slot-scope='{row}'>
                      <span title='签约时间' class='sign'>{{ $utils.parseTime(row.sign_start, '{y}年{m}月{d}签约') }}</span>
                      &nbsp;
                      <span title='当前状态'> {{
                          row.sign_status ? (row.sign_status == 'Y' ? '目前：在约' : '目前：解约') : ''
                        }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div style='padding: 5px;display: flex;flex-direction: row;justify-content: center;align-items: center'>
                <div>
                  <!--            <el-button type='primary'>全量初始化</el-button>-->
                </div>
                <div style='width: 180px'>
                  总监： {{ this.formData.manager_name || '-' }}
                  红人：{{ formData.kol_range ? formData.kol_range.length : 0 }} 位
                </div>
                <el-button round type='primary' icon='el-icon-plus' :loading='loadingCommit' :disabled='loadingCommit'
                           @click='handleSave(formData)'>考核部门及其红人
                </el-button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import UserSearch from '@/pages/user/components/UserSearch'
import MemberSelect from '@/pages/pa/components/MemberSelect'
import DeptKolSelect from '@/pages/pa/components/DeptKolSelect'

export default {
  name: 'PaDeptAdd',
  components: { DeptKolSelect, MemberSelect, UserSearch, ArtistSearch },
  props: {
    showDeptList: {
      type: Boolean,
      default() {
        return true
      }
    },
    showDeptAdd: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    title() {
      return `${this.searchCondition.year}年第${this.searchCondition.quarter}季度《${this.searchCondition.dept_name}》`
    }
  },
  data() {
    return {
      showAddBox: false,
      showMore: false,
      searchCondition: {
        id: null,
        dept_id: null,
        dept_name: null,
        year: null,
        quarter: null,
        manager_name: null,
        manager_id: null
      },
      deptList: [
        // { dept_id: '1', dept_name: '项目一部' },
        // { dept_id: '2', dept_name: '项目二部' }
      ],
      currentRow: {},
      currentKol: {},
      KolDataList: [],
      formData: {
        dept_id: null,
        dept_name: '',
        year: '',
        quarter: '',
        pid: null,
        'kol_range': [],
        'manager_id': '',
        'manager_name': ''
      },
      keyWord: null,
      dataList: [],
      loadingStatus: false,
      loadingInit: false,
      loadingCommit: false,
      total: 0,
      pageData: {
        page_size: 10,
        current_page: 1
      },
      showKolSelection: false,
      selection: [],
      ids: [],
      multiple: false
    }
  },
  methods: {
    show(row) {
      this.searchCondition = { ...row }
      this.showAddBox = true
      this.showMore = false
      this.currentRow = {}
      this.formData.pid = row.dept_id
      this.formData.year = row.year
      this.formData.quarter = row.quarter
      //获取当前指定中心部门的下级部门
      this.getNextDeptList()
    },
    close() {
      this.showAddBox = false
    },
    async getNextDeptList() {
      if (this.searchCondition.dept_id) {
        let { list } = await this.$api.getNextDeptList(this.searchCondition.dept_id)
        if (list) {
          list.forEach(item => {
            item.manager_name = null
            item.manager_id = null
          })
        }
        this.deptList = list || []
      }

    },
    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      // this.currentRow = { dept_id: row.dept_id, dept_name: row.dept_name }
      this.handleCurrentChange(row)
    },
    handleCurrentChange(val) {
      if (val) {
        this.currentRow = val
        this.formData.kol_range = []
        this.formData.dept_id = val.dept_id
        this.formData.dept_name = val.dept_name
        // this.$notify.info(`${this.currentRow.dept_name}`)
        this.handleLoadKol(val)
        this.$nextTick(() => {
          this.getCurrentLeader()
        })
      }
    },
    // 初始化部门勾选
    handleSelectionChange(val) {
      this.selection = val
      this.ids = val.map((item) => {
        return item.dept_id
      })
      this.multiple = val.length
      this.$nextTick(() => {
        //加载部门主管（总监）
        this.selection.forEach((row) => {
          let index = this.deptList.indexOf(row)
          if (this.$refs[`refMemberSelect_${row.dept_id}`])
            this.$refs[`refMemberSelect_${row.dept_id}`].show({ index: index })
        })
      })

    },
    handleSelectionRowChange() {

    },
    handleSelectArtist(obj) {
      if (obj && obj.id)
        this.currentKol = {
          artist_id: obj.id,
          nickname: obj.nickname,
          sign_start: obj.sign_start,
          sign_status: obj.sign_status
        }
    },
    appendToKolRange() {
      if (this.currentKol.artist_id) {
        let obj = { ...this.currentKol }
        if (this.formData.kol_range.findIndex(value => value.artist_id === obj.artist_id) === -1) {
          this.formData.kol_range.push(obj)
        }
      }
    },
    async getCurrentLeader() {

      if (this.currentRow.dept_id) {
        if (this.$refs['refDeptMemberSelect'])
          this.$refs['refDeptMemberSelect'].show()
      }
    },
    selectRowUser(obj, index) {

      if (obj.id) {
        this.$set(this.deptList[index], 'manager_id', obj.id)
        this.$set(this.deptList[index], 'manager_name', obj.nickname)
        // this.deptList[index]['manager_id'] = user.id
        // this.deptList[index]['manager_name'] = user.nickname
      }

    },
    selectUser(user) {
      this.formData.manager_id = user.id
      this.formData.manager_name = user.nickname
    },
    handleSave(formData) {

      // if (!formData.manager_id) {
      //   this.$notify.warning('总监必填')
      //   return
      // }
      if (!formData.dept_id) {
        this.$notify.warning('部门必选')
        return
      }
      formData.pid = this.searchCondition.id
      formData.year = this.searchCondition.year
      formData.quarter = this.searchCondition.quarter
      if (!formData.pid || !formData.year || !formData.quarter) {
        this.$notify.warning('上级部门、年份、季度必传')
        return
      }
      this.loadingCommit = true
      this.$api.initPaChiefRevenueQuarterData(formData)
      // this.$notify.success('添加成功')

      setTimeout(() => {
        this.loadingCommit = false
      }, 500)
    },
    async handleBatchSave() {
      //批量设置
      this.loadingCommit = true
      //追个添加
      const promises = this.selection.map(item => {
        return this.handleSave(item)
      })
      await Promise.all(promises)
      // this.$notify.success('已追加完毕')
      this.loadingCommit = false
      this.$emit('saved')
      this.showAddBox = false

    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getPaDeptList()
    },
    async getPaDeptList() {
      this.loadingStatus = true
      this.dataList = []
      let searchCondition = { pid: this.searchCondition.id }
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getPaDeptSettingList(searchCondition)
      this.dataList = list || []
      this.pageData.page_size = pages.page_size || 10
      this.pageData.current_page = pages.current_page || 1
      this.total = pages.total || 0
    },
    // eslint-disable-next-line no-unused-vars
    async handleLoadKol(row) {
      //加载部门下的红人
      let { info } = await this.$api.getPaDeptSetting({
        dept_id: row.dept_id,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        pid: this.searchCondition.id
      })
      if (info) {
        info.kol_range = info.kol_range ? JSON.parse(info.kol_range) : []
        this.formData.kol_range = [...info.kol_range]
      }

      // this.formData.manager_name = info.manager_name || ''
    },
    async handleInitItem(row) {
      this.loadingInit = true
      setTimeout(() => {
        this.loadingInit = false
      }, 2000)
      let { err } = await this.$api.initItemPaChiefRevenue(row.id)
      if (err) {
        if (err.length > 0) {
          let errMsgArr = err.map(item => {
            return item.err_msg
          })
          this.$message.warning(errMsgArr.join('、'))
        }

      } else {
        this.$notify.success('初始化完毕')
      }

    },
    // eslint-disable-next-line no-unused-vars
    handleRemove(row) {
      let msg = `确认移除部门：“${row.dept_name}”，移除后后续将不参与考核？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        let posIndex = this.dataList.indexOf(row)
        if (posIndex > -1) {
          this.dataList.splice(posIndex, 1)
        }
        if (row.id) {
          this.$api.deletePaDeptSetting(row.id)
        }
      }).catch(() => {
        this.$notify.info('取消操作')
      })


    },
    // eslint-disable-next-line no-unused-vars
    handleRemoveKol(tag, row) {

      // row.kol_range.splice(row.kol_range.indexOf(tag), 1);

    },
    showDeptKolSelection(row) {
      // this.showKolSelection = true
      this.$refs['refDeptKolSelect'].show(row)
    }

  }
}
</script>

<style scoped>

.add-panel {
  padding: 10px;
  margin: 10px;
}

.add-box {
  /*border: #e97ca1 1px dashed;*/
  display: flex;
  flex-direction: column;
  gap: 5px;
}

</style>
