<template>
  <el-dialog :visible.sync='showAddBox' v-bind='$attrs' v-on='$listeners'
             title='新增设置：MCN中心负责人' width='800px' append-to-body>
    <div class='add-panel shadow-effect' style='padding: 10px' v-if='showAddBox'>
<!--      <div style='display: flex;flex-direction: row;justify-content: space-between'>-->
<!--        <h3>新增考核MCN中心分组</h3>-->
<!--        <span class='el-icon-close' @click='showAddBox=false'></span>-->
<!--      </div>-->
      <el-form v-model='formData' :inline='false' label-width='88px'>
        <el-form-item label='季度：'>
          <div style='display: flex;flex-direction: row;gap: 5px'>
            <el-date-picker
                v-model='formData.year'
                type='year'
                format='yyyy年'
                value-format='yyyy'
                placeholder='选择年度' style='width: 140px'>
            </el-date-picker>
            <el-select v-model='formData.quarter'>
              <el-option :label='quarter.label' :key='index' :value='quarter.value'
                         v-for='(quarter,index) in quarters'></el-option>
            </el-select>
          </div>

        </el-form-item>
        <el-form-item label='部门：'>
          <CompanyCascader ref='refCompanySingleCascader' @handleSelect='selectDept' />
        </el-form-item>
        <el-form-item label='总监：'>
          <div style='display: flex;flex-direction: row;gap: 10px'>
            <MemberSelect ref='refDeptMemberSelect' placeholder='组员' :dept-id='formData.dept_id'
                          @handleSelect='selectUser'></MemberSelect>

            <el-button type='text' :icon='!showMore?"el-icon-caret-bottom":"el-icon-caret-top"'
                       @click='showMore=!showMore'>
              {{!showMore?'展开':'隐藏'}}
            </el-button>
          </div>

        </el-form-item>
        <el-form-item label='负责人：' v-if='showMore'>
          <UserSearch ref='refUserSearch' :keyword.sync='formData.manager_name'
                      @selected='selectUser'></UserSearch>
        </el-form-item>
        <el-form-item style='text-align: left'>
          <el-button type='primary' @click='handleSave'>添加考核部门</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>


</template>

<script>
import UserSearch from '@/pages/user/components/UserSearch'
import CompanyCascader from '@/components/dept/CompanyCascader'
import MemberSelect from '@/pages/pa/components/MemberSelect'

export default {
  name: 'PaSettingAdd',
  components: { MemberSelect, CompanyCascader, UserSearch },
  data() {
    return {
      loading: false,
      searchCondition: { dept_id: null, dept_name: null, manager_name: null, manager_id: null },
      showAddBox: false,
      formData: { year: null, quarter: null, dept_id: null, dept_name: null, manager_name: null, manager_id: null },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ],
      showMore:false,
    }
  },
  methods: {
    show() {
      this.loading = false
      this.showAddBox = true
      this.defaultQuarter()
    },
    close(){
      this.showAddBox = false
    },
    defaultQuarter() {
      //当前季度
      this.formData.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.formData.quarter = this.$utils.getCurrentQuarter()
    },
    selectDept(nodeIds) {
      // console.log(nodeIds)
      if (nodeIds) {
        this.formData.dept_id = nodeIds[0]
        this.formData.dept_name = this.$refs['refCompanySingleCascader'].getNodeName(nodeIds[0])
        this.$refs['refDeptMemberSelect'].show()
      }

    },
    selectUser(user) {
      this.formData.manager_id = user.id
      this.formData.manager_name = user.nickname
    },
    async handleSave() {
      if (!this.formData.manager_id) {
        this.$notify.info('总监必选')
        return
      }
      if (!this.formData.dept_id) {
        this.$notify.info('部门必选')
        return
      }
      let msg = `确认添加要考核的MCN中心部门：“${this.formData.dept_name}”，指定其总监为：“${this.formData.manager_name}”`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        await this.$api.savePaDeptSetting(this.formData)
        this.showAddBox = false
        this.loading = false
        this.$emit('saved')
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })
    }
  }
}
</script>

<style scoped>
.add-panel {
  padding: 10px;
  margin: 10px;
}

.add-box {
  /*border: #e97ca1 1px dashed;*/
  display: flex;
  flex-direction: row;
  gap: 5px;
}
</style>
