<template>
  <div style='display: flex;flex-direction: row;gap: 5px;'>
    <el-select v-bind='$attrs' v-model='value' placeholder='选择成员' @change='handleChange' clearable>
      <el-option v-for='(groupMember,index) in groupMembers' :key='`${deptId}_${index}`'
                 :value='groupMember.id'
                 :label='groupMember.nickname'>
        {{ groupMember.nickname || '' }} / {{ groupMember.position || '' }}
        <span class='leader' v-if='groupMember.is_leader==1'>{{ groupMember.is_leader == 1 ? '主管' : '' }}</span>
      </el-option>
    </el-select>
    <el-button v-if='showRefreshBtn' type='text' :loading='loading' icon='el-icon-refresh' title='刷新成员'
               @click='getCurrentOptions()' style='margin-left: 10px;font-size: 12px'>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'MemberSelect',
  data() {
    return {
      loading: false,
      showGroupMembers: false,
      groupMembers: [],
      value: null,
      refName: null
      // rowIndex: null
    }
  },
  props: {
    deptId: {
      type: [Number, String]
    },
    rowIndex: {
      type: Number,
      default() {
        return 0
      }
    },
    showRefreshBtn:{
      type: Boolean,
      default() {
        return true
      }
    }
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.getCurrentOptions()
      })
    },
    async getCurrentOptions() {
      this.groupMembers = []
      //组长
      if (!this.loading) {
        this.loading = true
        if (this.deptId) {
          let { list } = await this.$api.getDeptMembers(this.deptId)
          this.groupMembers = list || []
          if (list[0]) {

            this.value = list[0]['id']
            this.handleChange()
          }
          this.$emit('loaded', list)
        }

        this.loading = false
      }
    },

    handleChange() {
      let obj = this.groupMembers.find((value) => value.id === this.value)

      this.$emit('handleSelect', {
        id: this.value,
        nickname: obj ? obj.nickname : '',
        dept_id: this.deptId
      }, this.rowIndex)
    }
  }
}
</script>

<style scoped>

</style>
